import L from 'leaflet';
import React from 'react';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";

// Allow marker icons to show without loading leaflet css directly from CDN
if (!!L?.icon) {
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  });
}

const ProjectMap = class extends React.Component {
  state = {
    lat: 22.1,
    lng: 70.9,
    zoom: 5.5,
  }

  locations = [
    {
      description: 'Brahmanand Vidya Mandir School',
      position: [21.380938, 70.671207], 
      link: '/projects/education/brahmanand-vidya-mandir'
    },
    {
      description: 'BVM Specially Abled School',
      position: [21.380190, 70.673346], 
      link: '/projects/education/brahmanand-vidya-mandir'
    },
    {
      description: 'Gopalanand Vidya Mandir School',
      position: [23.382218, 70.511442],
      link: '/projects/education/gopalanand-vidya-mandir'
    },
    {
      description: 'Nividya Schools',
      position: [21.330014, 71.302932],
      link: '/projects/education/nividya-schools'
    },
    {
      description: 'Anand Dhara Projects',
      position: [21.342257, 70.753573],
      link: '/projects/education/anand-dhara'
    },
    {
      description: 'Bhagini Samaj Girls Hostel',
      position: [21.5088536, 70.454601],
      link: '/projects/education/bhagini-samaj'
    },
    {
      description: 'Jay Ambe Hospital',
      position: [21.383186, 70.668021],
      link: '/projects/health'
    },
    {
      description: 'Radhaba Sanyas Vrudh Ashram',
      position: [21.321200, 70.441160],
      link: '/projects/elderly'
    },
    {
      description: 'Kailash Dham Vrudh Ashram',
      position: [23.285749, 72.681324],
      link: '/projects/elderly'
    },
    {
      description: 'Prayag Vrudh Ashram',
      position: [20.752736, 70.998264],
      link: '/projects/elderly'
    },
    {
      description: 'Chaparda Vrudh Ashram',
      position: [21.380138, 70.672584],
      link: '/projects/elderly'
    },
  ]

  render() {
    const position = [this.state.lat, this.state.lng];

    const markers = this.locations.map((location) => {
      return (
        <Marker position={location.position} key={location.description}>
          <Popup>
            <a href={location.link}>{location.description}</a> 
          </Popup>
        </Marker>
      )
    });

    if (typeof window !== 'undefined') {
      return (
        <Map style={{height:'60vh'}} center={position} zoom={this.state.zoom}>
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {markers} 
          </MarkerClusterGroup>
        </Map>
      )
    }
    return null
  }
}

export default ProjectMap
